
import AuthManager from '~/fc/Auth/AuthManager'
export default {
  name: 'TryFree',
  computed: {
    authManager () { return new AuthManager(this) },
    loggedInAuth () { return this.authManager && this.authManager.auth ? !!this.authManager.auth.token : false },
    loggedIn () { return this.loggedInAuth },
    freeMember () { return !this.$store.state.subscription.loaded || this.$store.state.subscription.name === undefined || this.$store.state.subscription.name === 'Free'}
  },
  mounted () {
    if (document.getElementById('Footer')) { document.getElementById('Footer').style.paddingTop = '140px' }
  },
  destroyed () {
    if (document.getElementById('Footer')) { document.getElementById('Footer').style.paddingTop = '80px' }
  },
  methods: {
    redirectToPricing () {
      this.$router.push(this.localePath('/pricing'))
    }
  }
}
